/* ContactButton.module.css */

.button {
  position: relative;
  overflow: hidden;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Common style for both hover effects */
.hoverEffect {
  content: "";
  position: absolute;
  background: white;
  opacity: 0;
  transform: rotate(35deg);
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
}

/* Styles for the first hover effect */
.before {
  top: -50px;
  left: -150%;
  height: 20rem;
  width: 8rem;
}

.button:hover .before {
  left: 200%;
  opacity: 0.6;
}

/* Styles for the second hover effect */
.after {
  top: -50px;
  left: -100%;
  height: 155px;
  width: 50px;
}

.button:hover .after {
  left: 120%;
  opacity: 0.5;
}
