@tailwind base;
@tailwind components;
@tailwind utilities;

.font-sans {
  font-family: var(--font-montserrat), sans-serif;
}
.font-serif {
  font-family: var(--font-cormorant), serif;
}
.font-calligraphy {
  font-family: var(--font-allura), serif;
}

body {
  @apply bg-white font-sans font-normal text-colorGray-800 text-base md:text-lg;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}
.text-shad-wh {
  text-shadow: 0px 3px 1px 1px rgba(255, 255, 0255, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium tracking-wide;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
body main p {
  @apply mb-3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-serif text-colorRed-600;
}
h1 {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-4xl md:text-5xl lg:text-6xl font-bold mb-4;
}
h3 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h4 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h5 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h6 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-indigo-950 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.gradient1 {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.gradient1:before {
  @apply bg-fixed absolute bg-gradient-to-b to-black via-black/25 from-transparent bg-opacity-5 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.black-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.black-tint:before {
  @apply bg-fixed absolute bottom-0 bg-black left-0 right-0 top-0 w-full overflow-hidden;
}

.col-effect {
  @apply bg-no-repeat bg-center relative overflow-hidden w-full;
}
.col-effect:before {
  @apply bg-fixed absolute bg-black bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ff5e57; /* Custom dot color */
}

.slick-dots li.slick-active button:before {
  color: #ff5e57; /* Active dot color */
}
.btn-white {
  @apply bg-white hover:bg-transparent border-2 border-white text-black hover:text-white font-serif font-bold uppercase;
}
.btn-red {
  @apply bg-red-500 hover:bg-red-600 border-2 border-red-500 text-white font-medium uppercase;
}

.btn-green {
  @apply bg-olive-400 hover:bg-olive-800  text-white font-normal uppercase;
}
.slick-slider .slick-track {
  display: flex !important;
}

.slick-slider .slick-track .slick-slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slick-slider .slick-track .slick-active {
  opacity: 1 !important;
}
